footer {
    height: 300px;
    padding: 1rem 3rem;
    font-family: 'DM Serif Display', serif;
    font-family: 'Encode Sans', sans-serif;
    font-family: 'Noto Sans JP', sans-serif;
    font-family: 'Noto Serif JP', serif;
    font-family: 'Sawarabi Mincho', serif;
    font-family: 'Shippori Mincho', serif;
    background-color: rgb(135, 44, 44);
}
footer li{
    list-style: none;
}
footer li a{
    text-decoration: none;
    color: black
}
footer .footer-left{
    text-align: left;
    margin: 1rem;
}
footer .footer-left p {
    margin: 0.1rem;
}
footer .company-name{
    /* font-weight: bold; */
}
footer .icon {
    color: black;
    width: 2rem;
    height: 2rem;
}
@media screen and (max-width: 480px) {
    footer {
        margin: 0;
        padding: 2rem;
    }
}