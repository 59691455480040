.home {
    min-height: 70vh;
    color: white;
    background-image: url(../../img/todai-ichou3.jpeg);
    filter: opacity(0.7);
    background-position-y: 50%;
    position: relative;
}
.home h1 {
    text-align: left;
    font-weight: normal;
    margin: 0;
    padding: 4rem;
    font-size: 2.8rem;
}
.home ul {
    margin: 0;
    padding: 4rem;
}
.home ul li {
    list-style: none;
    text-align: right;
    padding: 1rem;
}
.home ul li a {
    text-decoration: none;
    color: white;
    font-size: 2.5rem;
}
.home ul li a::first-letter {
    font-size: 130%;
}
.home ul li a {
	display: inline-block;
	position: relative;
	text-decoration: none;
}
.home ul li a::after {
	position: absolute;
	content: '';
	bottom: 0;
	right: 0;
	width: 0;
	height: 2px;
	background: white;
	transition: all .3s ease 0s;
}
.home ul li a:hover {
	cursor: pointer;
}
.home ul li a:hover::after {
	width: 100%;
}
.cursor {
    width: 20px;
    height: 20px;
    background-color: rgb(105, 105, 105);
    position: fixed;
    border-radius: 50%;
    transition: .3s;
}
.home:hover .cursor {
    z-index: 100;
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgba(159, 159, 159, 0.5);
}
@media screen and (max-width: 480px) {
    .home {
        min-height: 70vh;
        color: white;
        background-image: url(../../img/todai-ichou3.jpeg);
        filter: opacity(0.7);
        background-position-y: 40%;
        position: relative;
    }
    .home h1 {
        margin: 0;
        padding: 2rem;
        font-size: 30px;
    }
    .home ul {
        margin: 0;
        padding: 2rem;
    }
    .home ul li {
        list-style: none;
        text-align: right;
        padding: 1rem;
    }
    .home ul li a {
        text-decoration: none;
        color: white;
        font-size: 30px;
    }
    .home ul li a::first-letter {
        font-size: 130%;
    }
    .home ul li a {
        display: inline-block;
        position: relative;
        text-decoration: none;
    }
}