.App {
  text-align: center;
  font-family: 'DM Serif Display', serif;
  font-family: 'Encode Sans', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
  font-family: 'Noto Serif JP', serif;
  font-family: 'Sawarabi Mincho', serif;
  font-family: 'Shippori Mincho', serif;
  color: rgb(255, 255, 255);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .App {
  background-image: url(../src/img/todai-ichou.jpeg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-attachment: fixed;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* .cursor {
  width: 20px;
  height: 20px;
  background-color: rgb(105, 105, 105);
  position: fixed;
  border-radius: 50%;
  transition: .3s;
} */
.cursor {
  z-index: 100;
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: rgba(159, 159, 159, 0.5);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}