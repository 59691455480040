@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Encode+Sans&family=Noto+Sans+JP:wght@100;200;300;500&family=Noto+Serif+JP:wght@200&family=Sawarabi+Mincho&family=Shippori+Mincho&display=swap');

.active {
    font-weight: normal;
    border-bottom: 2px solid white;
}
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4rem;
    font-family: 'DM Serif Display', serif;
    font-family: 'Encode Sans', sans-serif;
    font-family: 'Noto Sans JP', sans-serif;
    font-family: 'Noto Serif JP', serif;
    font-family: 'Sawarabi Mincho', serif;
    font-family: 'Shippori Mincho', serif;
    background-color: rgb(135, 44, 44);
}
.nav-left {
    display: flex;
    align-items: center;
}
nav .title {
    text-decoration: none;
    margin: 1rem;
    font-size: 1.5rem;
    color: white;
}
nav .signature {
    width: 14rem;
}
nav .icon-dili {
    height: 2.5rem;
}
nav .icon_faq {
    height: 2.5rem;
}
nav ul{
    display: flex;
}
nav li{
    display: flex;
    list-style: none;
    padding: 0.5rem;
    margin: 0 0.5rem;
    align-items: center;
    font-size: 1.2rem;
}
nav li a {
    text-decoration: none;
    width: fit-content;
    color: white;
}
@media screen and (max-width: 480px) {
    nav {
        margin: 0;
        padding: 15px 15px;
        height: 50px;
    }
    nav .title {
        /* font-weight: bold; */
        text-decoration: none;
        margin: 1rem;
        font-size: 1rem;
        color: black
    }
    nav .signature {
        width: 11rem;
    }
    nav .icon-dili {
        height: 2rem;
    }
    nav .side-bar {
        display: inline-block;
        position: absolute;
        top: 80px;
        right: 0;
        background-color: rgb(99, 38, 38);;
        z-index: 10;
        transition: .5s;
    }
    nav .side-bar ul{
        display: block;
    }
}