.philosophy {
    min-height: 100vh;
    padding: 2rem 4rem;
    /* background-image: url(../../img/todai-ichou2.jpeg); */
    background-image: url(../../img/rOrMSbGwmmsoF1x1696180165_1696180172.png);
    background-size: cover;
    /* filter: opacity(0.7); */
    background-position-y: 50%;
    color: black
}
.philosophy h1 {
    margin: 0;
    padding: 2rem 0;
    text-align: left;
    font-size: 3rem;
    font-weight: normal;
}
.philosophy p {
    font-size: 1.5rem;
    text-align: left;
}
@media screen and (max-width: 480px) {
    .philosophy {
        min-height: 100vh;
        padding: 1rem 2rem;
    }
    .philosophy h1 {
        margin: 0;
        padding: 1rem 0;
        text-align: left;
        font-size: 35px;
        font-weight: normal;
    }
    .philosophy p {
        font-size: 15px;
        text-align: left;
    }
}