.team {
    min-height: 100vh;
    padding: 2rem 4rem;
    /* background-image: url(../../img/todai-ichou2.jpeg); */
    background-image: url(../../img/rOrMSbGwmmsoF1x1696180165_1696180172.png);
    background-size: cover;
    /* filter: opacity(0.7); */
    background-position-y: 50%;
    color: black
}
.team h1 {
    margin: 0;
    padding: 2rem 0;
    text-align: left;
    font-size: 3rem;
    font-weight: normal;
}
.team p {
    font-size: 1.5rem;
    text-align: left;
}
.team p::first-letter {
    /* font-weight: bold; */
    font-size: 150%;
}
.team ul {
    list-style: none;
}
.team ul li h2 {
    text-align: left;
    font-size: 30px;
}
.team ul li p {
    font-size: 20px;
    margin: 0 1rem;
}
@media screen and (max-width: 480px) {
    .team {
        min-height: 100vh;
        padding: 1rem 2rem;
    }
    .team h1 {
        margin: 0;
        padding: 1rem 0;
        text-align: left;
        font-size: 35px;
        font-weight: normal;
    }
    .team p {
        font-size: 15px;
        text-align: left;
    }
    .team ul {
        list-style: none;
        padding: 0;
    }
    .team ul li h2 {
        text-align: left;
        font-size: 30px;
        margin: 1rem;
    }
    .team ul li p {
        font-size: 15px;
    }  
}