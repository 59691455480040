.contact {
    min-height: 100vh;
    padding: 2rem 4rem;
    /* background-image: url(../../img/todai-ichou2.jpeg); */
    background-image: url(../../img/rOrMSbGwmmsoF1x1696180165_1696180172.png);
    background-size: cover;
    /* filter: opacity(0.7); */
    background-position-y: 50%;
    color: black
}
.contact h1 {
    margin: 0;
    padding: 2rem 0;
    text-align: left;
    font-size: 3rem;
    font-weight: normal;
}
.contact p {
    font-size: 1.5rem;
    text-align: left;
}
.contact p::first-letter {
    /* font-weight: bold; */
    font-size: 150%;
}
.contact p.error, .contact p.success {
    margin: 1rem;
    text-align: center;
}
.contact p.error::first-letter, .contact p.success::first-letter {
    /* font-weight: bold; */
    font-size: 100%;
}
.contact form {
    width: 50%;
    margin: 0 auto;
}
.contact .flex-box {
    display: flex;
    justify-content: space-between;
}
.contact .flex-box .name, .contact .flex-box .kana{
    width: 48%;
}
.contact label {
    /* font-weight: bold; */
    text-align: left;
    margin: 0 0.5rem;
    display: block;
}
.contact .formInput{
    width: 100%;
    height: 2rem;
    text-align: left;
    font-size: 1rem;
    margin: 0 0 1rem 0;
    font-family: inherit;
}
.contact .formInputMessage{
    height: 8rem;
}
.contact .unSent {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: none;
    white-space: normal;
    font-weight: 700;
    text-align: center;
    padding: 16px 14px 18px;
    color: #616467;
    box-shadow: inset 0 0 0 2px #616467;
    background-color: transparent;
    height: 48px;
    margin: 1rem;
}
.contact .unSent:hover{
    color: black;
    background-color: rgba(255, 255, 255, 0.768);
}
.contact .hasSent {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .3s;
    letter-spacing: 2px;
    min-width: 160px;
    border: none;
    text-transform: none;
    white-space: normal;
    font-weight: 500;
    text-align: center;
    padding: 16px 14px 18px;
    color: white;
    background-color: rgb(0, 168, 0);
    height: 48px;
    margin: 1rem;
}
@media screen and (max-width: 480px) {
    .contact {
        min-height: 100vh;
        padding: 1rem 2rem;
    }
    .contact form {
        width: 100%;
    }
    .contact h1 {
        margin: 0;
        padding: 1rem 0;
        text-align: left;
        font-size: 35px;
        font-weight: normal;
    }
    .contact p {
        font-size: 15px;
        text-align: left;
    }
    .contact .flex-box .name, .contact .flex-box .kana{
        width: 46%;
    }
}